import { createContext, useContext, useState, ReactNode, useRef } from 'react'
import {
  Question,
  Question2,
  QuestionAnswered,
  QuestionAnswered2,
  SelfAssessmentQuestion,
} from '../apis/entities/question.entity'
import { Course } from '../apis/entities/course.entity'
import { Answer } from '../apis/entities/answer.entity'
import { API } from '../apis/API'
import { ActivityLogRequest } from '../apis/requests/activity-log.request'
import {
  Assessment,
  Assessment2,
  AssessmentResult,
  CapabilityResult,
  FocusAreaResult,
  ProficiencyResult,
  RequirementResult,
} from '../apis/entities/assessment.entity'
import { AnswerRequest } from '../apis/requests/answer.request'
import { isProduction } from '../utils/EnvUtils'
import { Candidate } from '../apis/entities/candidate.entity'
import { AnswerRequestV2 } from '../apis/requests/answer-v2.request'

interface DataContextProps {
  candidateId: string
  setCandidateId: (candidateId: string) => void

  assessmentId: string
  setAssessmentId: (assessmentId: string) => void

  assessment: Assessment | Assessment2 | undefined
  setAssessment: (assessment: Assessment | Assessment2 | undefined) => void

  progress: number
  setProgress: (progress: number) => void
  incrementProgress: () => void

  courses: Course[]
  setCourses: (courses: Course[]) => void

  questions: Question[]
  questions2: Question2[]
  setQuestions: (questions: Question[]) => void
  setQuestions2: (questions: Question2[]) => void
  // questionsAnswered: QuestionAnswered[]
  // setQuestionsAnswered: (questions: QuestionAnswered[]) => void
  // questionsAnswered2: QuestionAnswered2[]
  // setQuestionsAnswered2: (questions: QuestionAnswered2[]) => void

  submitAnswer: (
    questionId: string,
    questionNumber: number,
    answerId: string,
  ) => Promise<void>

  submitSelfAssessment: () => Promise<void>

  selfAssessedLevel: number
  setSelfAssessedLevel: (level: number) => void

  currentLevel: React.MutableRefObject<number>

  // setCurrentLevel: (level: number) => void

  scores: number[]
  setScores: (scores: number[]) => void
  addScore: (score: number) => void

  getSelfAssessmentQuestion: () => SelfAssessmentQuestion | undefined

  currentQuestion: Question | undefined
  setCurrentQuestion: (question: Question | undefined) => void
  currentQuestion2: Question2 | undefined
  setCurrentQuestion2: (question: Question2 | undefined) => void

  finalLevel: number
  setFinalLevel: (level: number) => void

  startTest: () => void
  endTest: () => void

  confidence: number
  setConfidence: (confidence: number) => void

  // questionCount: number
  // setQuestionCount: (questionCount: number) => void

  updateCurrentQuestion: () => void
  adjustLevel: () => void
  checkWorkflow: () => void

  postActivityLog: (event: string, data: string) => Promise<void>
  step: number
  setStep: (step: number) => void

  submitted: boolean
  setSubmitted: (submitted: boolean) => void

  startTime: number
  setStartTime: (startTime: number) => void

  isPublic: boolean
  setIsPublic: (isPublic: boolean) => void

  candidate: Candidate | undefined
  setCandidate: (candidate: Candidate | undefined) => void

  warmup: () => Promise<void>

  version: React.MutableRefObject<number>
  questionsAnswered: React.MutableRefObject<QuestionAnswered[]>
  questionsAnswered2: React.MutableRefObject<QuestionAnswered2[]>
  assessmentResult: React.MutableRefObject<AssessmentResult | undefined>

  updateResult: (questionAnswered2: QuestionAnswered2) => void
  updateCurrentQuestion2: () => void

  postSubmissionStart: (candidateId: string, debug?: boolean) => Promise<void>
  postSubmissionStatus: (candidateId: string) => Promise<boolean>

  autoAnswerRate: number | undefined
  setAutoAnswerRate: (rate: number | undefined) => void
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = (): DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}
export const DataProvider = ({ children }: Props) => {
  const [candidateId, setCandidateId] = useState<string>('')
  const [assessmentId, setAssessmentId] = useState<string>('')
  const [assessment, setAssessment] = useState<
    Assessment | Assessment2 | undefined
  >(undefined)
  const [progress, setProgress] = useState(0)
  const [questions, setQuestions] = useState<Question[]>([])
  const [questions2, setQuestions2] = useState<Question2[]>([])
  const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(
    undefined,
  )
  const [currentQuestion2, setCurrentQuestion2] = useState<
    Question2 | undefined
  >(undefined)
  // const [questionsAnswered, setQuestionsAnswered] = useState<
  //   QuestionAnswered[]
  // >([])
  // const [questionsAnswered2, setQuestionsAnswered2] = useState<
  //   QuestionAnswered2[]
  // >([])
  const questionsAnswered = useRef<QuestionAnswered[]>([])
  const questionsAnswered2 = useRef<QuestionAnswered2[]>([])
  const [courses, setCourses] = useState<Course[]>([])
  const [selfAssessedLevel, setSelfAssessedLevel] = useState(-1)

  // const [currentLevel, setCurrentLevel] = useState(-1)
  const currentLevel = useRef(-1)

  const [scores, setScores] = useState<number[]>([])
  const [finalLevel, setFinalLevel] = useState(-1)
  // to count time taken to answer a question
  const [actionTime, setActionTime] = useState(0)
  const [confidence, setConfidence] = useState(0)
  // const [questionCount, setQuestionCount] = useState(0)
  const [step, setStep] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  // cache answer requests for submission at the end
  const refAnswerRequests = useRef<AnswerRequest[]>([])
  const refAnswerRequests2 = useRef<AnswerRequestV2[]>([])
  const [startTime, setStartTime] = useState(0)

  const [isPublic, setIsPublic] = useState(false)
  const [candidate, setCandidate] = useState<Candidate | undefined>(undefined)

  const assessmentResult = useRef<AssessmentResult | undefined>(undefined)

  const version = useRef(-1)

  const [autoAnswerRate, setAutoAnswerRate] = useState<number | undefined>(
    undefined,
  )

  const incrementProgress = () => {
    const normalizedProgress = Math.max(
      0,
      Math.min(progress + 1 / (assessment?.setup.questionCountLimit ?? 10), 1),
    )
    setProgress(normalizedProgress)
  }

  const addScore = (score: number) => {
    setScores([...scores, score])
  }

  const answers: Answer[] = courses.map((sourceItem, index) => ({
    id: `${index}`,
    text: sourceItem.name + ': ' + sourceItem.title,
    isCorrect: true,
  }))

  const getSelfAssessmentQuestion = (): SelfAssessmentQuestion | undefined => {
    return {
      id: '1',
      text: 'Which of these course descriptions do you think is the best fit for you? This will help us gauge where to start with our placement test.',
      level: 0,
      hints: courses.map((course) => course.description),
      answers: answers,
      courses: courses,
    }
  }

  const submitAnswer = async (
    questionId: string,
    questionNumber: number,
    answerId: string,
  ) => {
    if (submitted && isProduction) {
      console.log('answer not submitted as test is already submitted')
      return
    }
    const now = Date.now()
    const timeTakenInSeconds = (now - actionTime) / 1000
    console.log('timeTaken', timeTakenInSeconds)
    // update time
    setActionTime(now)

    if (version.current === 1) {
      const answer = currentQuestion?.answers.find(
        (answer) => answer.id === answerId,
      )
      if (answer) {
        const request: AnswerRequest = {
          candidateId: candidateId,
          assessmentId: assessmentId,
          questionId: questionId,
          questionNumber: questionNumber,
          answer: answer?.text ?? '',
          isCorrect: answer?.isCorrect ?? false,
          level: currentQuestion?.level ?? -1, //currentLevel,
          currentLevel: currentLevel.current,
          duration: timeTakenInSeconds,
        }
        refAnswerRequests.current.push(request)
      } else {
        console.log('answer not found')
      }
    } else if (version.current === 2) {
      if (answerId) {
        const request: AnswerRequestV2 = {
          candidateId: candidateId,
          assessmentId: assessmentId,
          questionId: questionId,
          questionNumber: questionNumber,
          questionText: currentQuestion2?.name ?? '',
          answer: currentQuestion2?.options[Number(answerId)] ?? '',
          level: currentQuestion2?.level ?? -1,
          focusArea: currentQuestion2?.focusArea ?? 0,
          capability: currentQuestion2?.capability ?? 0,
          requirement: currentQuestion2?.requirement ?? 0,
          score: currentQuestion2?.answerIndex === Number(answerId) ? 1 : 0,
          duration: timeTakenInSeconds,
        }
        refAnswerRequests2.current.push(request)
      } else {
        console.log('answer not found')
      }
    }
  }

  const submitSelfAssessment = async () => {
    if (submitted && isProduction) {
      console.log('self assessment not submitted as test is already submitted')
      return
    }

    try {
      // submit all answers
      console.log(`submitting answers: ${refAnswerRequests.current.length}`)
      if (version.current === 1) {
        await API.postAnswers(refAnswerRequests.current)
      } else if (version.current === 2) {
        await API.postAnswersV2(refAnswerRequests2.current)
      }
    } catch (error) {
      console.error(error)
    }

    // retry 2 times if failed, to solve the first time failure by timeout
    for (let i = 0; i < 3; i++) {
      try {
        let success = false
        if (version.current === 1) {
          success = await API.postSubmissionSubmit(
            candidateId,
            finalLevel,
            !isProduction,
          )
        } else if (version.current === 2) {
          success = await API.postSubmissionSubmitV2(
            candidateId,
            assessmentResult.current!,
            !isProduction,
          )
        }
        if (success) {
          console.log('submitted')
          break
        } else {
          console.log('not submitted, try again.')
        }
      } catch (error) {
        console.error(error)
        console.log('not submitted, try again.')
      }
    }
  }

  const startTest = () => {
    // set current timestamp
    setActionTime(Date.now())
    setStartTime(Date.now())
    // reset
    refAnswerRequests.current = []
  }

  const endTest = () => {
    setActionTime(0)
  }

  const updateCurrentQuestion = () => {
    console.log('updateCurrentQuestion')

    console.log('updateCurrentQuestion currentLevel:', currentLevel.current)

    const minQuestionLevel = courses.reduce(
      (min, course) => (course.level < min ? course.level : min),
      courses[0].level,
    )
    const maxQuestionLevel = courses.reduce(
      (max, course) => (course.level > max ? course.level : max),
      courses[0].level,
    )

    let questionLevel = currentLevel.current
    if (questionLevel < minQuestionLevel) {
      questionLevel = minQuestionLevel
    } else if (questionLevel > maxQuestionLevel) {
      questionLevel = maxQuestionLevel
    }

    // get question
    const questionLevelSet = questions.filter(
      (question) => question.level === questionLevel,
    )

    let selectedQuestion =
      questionLevelSet.length > 0
        ? questionLevelSet[Math.floor(Math.random() * questionLevelSet.length)]
        : questions[Math.floor(Math.random() * questions.length)]

    // override for CI
    if (process.env.REACT_APP_CI === 'true') {
      selectedQuestion =
        questionLevelSet.length > 0 ? questionLevelSet[0] : questions[0]
    }

    console.log('selectedQuestion', selectedQuestion)
    if (selectedQuestion) {
      const remainingQuestions = questions.filter(
        (question) => question.id !== selectedQuestion.id,
      )
      setQuestions(remainingQuestions)
    }
    setCurrentQuestion(selectedQuestion)
  }

  // lowest in the framework
  const bottomLevel = (assessment as Assessment2)?.framework?.levels?.reduce(
    (min, proficiency) => {
      return proficiency.level < min ? proficiency.level : min
    },
    Infinity,
  )

  // highest in the framework
  const topLevel = (assessment as Assessment2)?.framework?.levels?.reduce(
    (max, proficiency) => {
      return proficiency.level > max ? proficiency.level : max
    },
    -Infinity,
  )

  const updateCurrentQuestion2 = () => {
    if (!assessmentResult.current) {
      assessmentResult.current = initAssessmentResult()
    }

    //approach 1
    //identify missing focus areas by filtering out assessed focus areas
    const minFocusAreaAssessedCount =
      assessmentResult.current.focusAreaResults?.reduce((min, focusArea) => {
        return focusArea.questionAssessedCount < min
          ? focusArea.questionAssessedCount
          : min
      }, Infinity)

    //unassessed focus areas or all focus areas
    const focusAreaResultSelectionPool =
      minFocusAreaAssessedCount === 0
        ? assessmentResult.current.focusAreaResults.filter(
            (focusArea) =>
              focusArea.questionAssessedCount === minFocusAreaAssessedCount,
          ) // unassessed focus areas
        : assessmentResult.current.focusAreaResults //all focus areas

    //approach 2
    //identify under assessed capabilities for all focus areas
    //capabilities from selected focus areas
    let capabilityResultSelectionPool = focusAreaResultSelectionPool.flatMap(
      (focusAreaResult) =>
        focusAreaResult.capabilityResults.flatMap(
          (capabilityResult) => capabilityResult,
        ),
    )
    // const minCapabilityAssessedCount = capabilityResultSelectionPool?.reduce(
    //   (min, capability) => {
    //     return capability.questionAssessedCount < min
    //       ? capability.questionAssessedCount
    //       : min
    //   },
    //   Infinity,
    // )
    const unassessedCapabilityPool = capabilityResultSelectionPool.flatMap(
      (capability) =>
        capability.questionAssessedCount === 0 ? capability : [],
    )
    // const minAssessedCapabilityPool = capabilityResultSelectionPool.flatMap(
    //   (capability) =>
    //     capability.questionAssessedCount === minCapabilityAssessedCount
    //       ? capability
    //       : [],
    // )

    if (unassessedCapabilityPool.length > 0) {
      //select unassessed
      capabilityResultSelectionPool = unassessedCapabilityPool
    } else {
      //select capabilities with uncertain level
      //no failed, has passed, not capped
      capabilityResultSelectionPool.filter(
        (capability) =>
          (capability.lowestFailingLevel === null && //no failed
            capability.highestPassingLevel !== null &&
            capability.highestPassingLevel < topLevel) ||
          (capability.highestPassingLevel === null && //no passed
            capability.lowestFailingLevel !== null &&
            capability.lowestFailingLevel > bottomLevel),
      )
    }

    //select random min assessed capability
    const targetCapabilityResult =
      capabilityResultSelectionPool![
        Math.floor(Math.random() * capabilityResultSelectionPool!.length)
      ]
    console.log('capabilityResultSelectionPool', capabilityResultSelectionPool)

    // target focusArea
    const targetFocusArea = targetCapabilityResult?.focusArea
    const targetFocusAreaResult =
      assessmentResult.current.focusAreaResults.find(
        (focusArea) => focusArea.focusArea === targetFocusArea,
      )!

    // target level
    // const focusAreaHighestCapabilityLevel = findHighestLevelInCapabilities(
    //   assessmentResult.current.focusAreaResults.find(
    //     (focusArea) => focusArea.focusArea === targetFocusArea,
    //   )!.capabilityResults,
    // )
    // const focusAreaLowestCapabilityLevel = findLowestLevelInCapabilities(
    //   assessmentResult.current.focusAreaResults.find(
    //     (focusArea) => focusArea.focusArea === targetFocusArea,
    //   )!.capabilityResults,
    // )
    // const currentCapabilityLevel = targetCapabilityResult?.level

    // //lowest in the capability
    // const bottomLevel = targetCapabilityResult!.proficiencyResults.reduce(
    //   (min, proficiency) => {
    //     return proficiency.level < min ? proficiency.level : min
    //   },
    //   Infinity,
    // )

    // //highest in the capability
    // const topLevel = targetCapabilityResult!.proficiencyResults.reduce(
    //   (max, proficiency) => {
    //     return proficiency.level > max ? proficiency.level : max
    //   },
    //   -Infinity,
    // )

    const levelChallengeJumpCount = Math.round((bottomLevel + topLevel) / 3)
    const levelSkipJumpCount = Math.round((bottomLevel + topLevel) / 4)

    //get current random range
    let highBound = 5 //Math.floor((bottomLevel + topLevel) / 2) //start from 1 //bottomLevel//
    let lowBound = 4 //Math.floor((bottomLevel + topLevel) / 2)

    //if no capability reference, refer to focus area
    if (
      targetCapabilityResult.highestPassingLevel === null &&
      targetCapabilityResult.lowestFailingLevel === null
    ) {
      //adjust capability level by focus area
      console.log('adjust level by focus area')
      if (
        targetFocusAreaResult.highestPassingLevel !== null &&
        targetFocusAreaResult.lowestFailingLevel === null
      ) {
        //has pass, no fail, challenge higher
        highBound =
          targetFocusAreaResult.highestPassingLevel + levelChallengeJumpCount
        lowBound =
          targetFocusAreaResult.highestPassingLevel + levelSkipJumpCount
      } else if (
        targetFocusAreaResult.lowestFailingLevel !== null &&
        targetFocusAreaResult.highestPassingLevel === null
      ) {
        //has fail, no pass, challenge lower
        highBound =
          targetFocusAreaResult.lowestFailingLevel - levelSkipJumpCount
        lowBound =
          targetFocusAreaResult.lowestFailingLevel - levelChallengeJumpCount
      } else if (
        targetFocusAreaResult.lowestFailingLevel !== null &&
        targetFocusAreaResult.highestPassingLevel !== null
      ) {
        //has pass, has fail
        highBound = targetFocusAreaResult.lowestFailingLevel - 1
        lowBound = targetFocusAreaResult.highestPassingLevel + 1
      }
    } else {
      //has capability reference
      //adjust capability level by capability
      if (
        targetCapabilityResult.highestPassingLevel !== null &&
        targetCapabilityResult.lowestFailingLevel === null
      ) {
        //has pass, no fail, challenge higher
        highBound =
          targetCapabilityResult.highestPassingLevel + levelChallengeJumpCount
        lowBound =
          targetCapabilityResult.highestPassingLevel + levelSkipJumpCount
      } else if (
        targetCapabilityResult.lowestFailingLevel !== null &&
        targetCapabilityResult.highestPassingLevel === null
      ) {
        //has fail, no pass, challenge lower
        highBound =
          targetCapabilityResult.lowestFailingLevel - levelSkipJumpCount
        lowBound =
          targetCapabilityResult.lowestFailingLevel - levelChallengeJumpCount
      } else if (
        targetCapabilityResult.lowestFailingLevel !== null &&
        targetCapabilityResult.highestPassingLevel !== null
      ) {
        //has pass, has fail
        highBound = targetCapabilityResult.lowestFailingLevel - 1
        lowBound = targetCapabilityResult.highestPassingLevel + 1
      }
      // if (targetCapabilityResult.highestPassingLevel) {
      //   //has pass
      //   highBound =
      //     targetCapabilityResult.highestPassingLevel + levelChallengeJumpCount
      // } else if (targetCapabilityResult.lowestFailingLevel !== null) {
      //   //no pass, has fail
      //   highBound =
      //     targetCapabilityResult.lowestFailingLevel - levelSkipJumpCount
      // }

      // if (targetCapabilityResult.lowestFailingLevel !== null) {
      //   //has fail
      //   //challenge lower
      //   lowBound =
      //     targetCapabilityResult.lowestFailingLevel - levelChallengeJumpCount
      // } else if (targetCapabilityResult.highestPassingLevel !== null) {
      //   //no fail, has pass
      //   //set higher minimum
      //   lowBound =
      //     targetCapabilityResult.highestPassingLevel + levelSkipJumpCount
      // }
    }

    if (highBound > topLevel) {
      highBound = topLevel
    }
    if (highBound < bottomLevel) {
      highBound = bottomLevel
    }
    if (lowBound < bottomLevel) {
      lowBound = bottomLevel
    }
    if (lowBound > topLevel) {
      lowBound = topLevel
    }
    if (lowBound > highBound) {
      const temp = lowBound
      lowBound = highBound
      highBound = temp
    }

    //if the estimated current level not assessed, assess it. Otherwise, draw random
    /*const isCurrentLevelAssessed =
      targetCapabilityResult.level !== null
        ? (targetCapabilityResult?.proficiencyResults.find(
            (proficiency) =>
              proficiency.level === Math.round(targetCapabilityResult!.level!),
          )?.questionAssessedCount ?? 0) > 0
        : false
    
    const targetLevel =
      targetCapabilityResult.level && !isCurrentLevelAssessed
        ? targetCapabilityResult!.level
        : getRandomIntegerBetween(lowBound, highBound)*/

    // let targetLevel = getRandomIntegerBetween(lowBound, highBound)
    let proficiencyLevelsPool = targetCapabilityResult.proficiencyResults
    const unassessedProficiencyLevels = proficiencyLevelsPool.filter(
      (proficiency) => proficiency.questionAssessedCount === 0,
    )
    if (unassessedProficiencyLevels.length > 0) {
      proficiencyLevelsPool = unassessedProficiencyLevels
    }
    const unassessedProficiencyLevelsInBound = proficiencyLevelsPool.filter(
      (proficiency) =>
        proficiency.level >= lowBound && proficiency.level <= highBound,
    )
    if (unassessedProficiencyLevelsInBound.length > 0) {
      proficiencyLevelsPool = unassessedProficiencyLevelsInBound
    }

    //get random level
    let targetLevel =
      proficiencyLevelsPool[
        Math.floor(Math.random() * proficiencyLevelsPool.length)
      ].level

    // const targetLevel = getRandomIntegerBetween(lowBound, highBound)

    const targetProficiencyResult =
      targetCapabilityResult?.proficiencyResults.find(
        (proficiency) => proficiency.level === targetLevel,
      )

    // console.log('targetProficiencyResult', targetProficiencyResult)

    const minRequirementAssessedCount =
      targetProficiencyResult?.requirementResults.reduce((min, requirement) => {
        return requirement.questionAssessedCount < min
          ? requirement.questionAssessedCount
          : min
      }, Infinity)
    const minAssessedRequirementPool =
      targetProficiencyResult?.requirementResults.flatMap((requirement) =>
        requirement.questionAssessedCount === minRequirementAssessedCount
          ? requirement
          : [],
      )

    // console.log('minAssessedRequirementPool', minAssessedRequirementPool)

    //get random requirement
    const targetRequirementResult =
      minAssessedRequirementPool![
        Math.floor(Math.random() * minAssessedRequirementPool!.length)
      ]
    // console.log('targetRequirementResult', targetRequirementResult)

    let questionsUnderRequirements = questions2.filter(
      (question) =>
        question.level === targetLevel &&
        question.focusArea === targetCapabilityResult?.focusArea &&
        question.capability === targetCapabilityResult?.capability &&
        question.requirement === targetRequirementResult?.requirement,
    )
    console.log(
      'selecting questions',
      `${targetCapabilityResult?.focusArea}.${targetCapabilityResult?.capability}.${targetLevel}.${targetRequirementResult?.requirement}`,
    )

    //////////////////////////

    // console.log('updateCurrentQuestion')
    let selectedQuestion =
      questionsUnderRequirements.length > 0
        ? questionsUnderRequirements[
            Math.floor(Math.random() * questionsUnderRequirements.length)
          ]
        : questionsUnderRequirements[
            Math.floor(Math.random() * questionsUnderRequirements.length)
          ]

    if (!selectedQuestion) {
      //relax level
      // questionsUnderRequirements = questions2.filter(
      //   (question) =>
      //     question.focusArea === targetCapabilityResult?.focusArea &&
      //     question.capability === targetCapabilityResult?.capability,
      // )
      // selectedQuestion =
      //   questionsUnderRequirements[
      //     Math.floor(Math.random() * questionsUnderRequirements.length)
      //   ]
    }
    if (selectedQuestion) {
      const remainingQuestions = questions2.filter(
        (question) => question._id !== selectedQuestion._id,
      )
      setQuestions2(remainingQuestions)
    }
    setCurrentQuestion2(selectedQuestion)

    //summary
    console.log('========selection summary========')
    console.log(
      'targetFocusArea',
      targetFocusArea,
      'current level',
      assessmentResult.current.focusAreaResults.find(
        (focusArea) => focusArea.focusArea === targetFocusArea,
      )!.level,
    )

    console.log('selected question Level', targetLevel)
  }

  const getRandomLevel = (): number => {
    const minCourseLevel =
      assessment?.courses.reduce(
        (min, course) => (course.level < min ? course.level : min),
        assessment?.courses[0].level,
      ) ?? 1
    const maxCourseLevel =
      assessment?.courses.reduce(
        (max, course) => (course.level > max ? course.level : max),
        assessment?.courses[0].level,
      ) ?? 1

    console.log(
      'getRandomLevel',
      Math.floor(Math.random() * (maxCourseLevel - minCourseLevel + 1)) +
        minCourseLevel,
    )
    return (
      Math.floor(Math.random() * (maxCourseLevel - minCourseLevel + 1)) +
      minCourseLevel
    )
  }

  const getRandomIntegerBetween = (
    min: number,
    max: number,
    except: number | null = null,
  ): number => {
    // Ensure that min and max are integers
    min = Math.floor(min)
    max = Math.floor(max)

    if (except !== null) {
      if (min === max) {
        // If min and max are the same, return min
        return min
      }
    }

    let random
    do {
      // Calculate the random integer within the specified range
      random = Math.floor(Math.random() * (Math.abs(max - min) + 1)) + min
    } while (random === except)

    return random
  }

  const initAssessmentResult = (): AssessmentResult => {
    //init results
    //focusareas
    const focusAreaResults: FocusAreaResult[] = (
      assessment as Assessment2
    ).framework.areas.map((focusArea) => {
      //capabiliities
      const capabilityResults: CapabilityResult[] = focusArea.capabilities.map(
        (capability) => {
          //proficiencies
          const proficiencyResults: ProficiencyResult[] =
            capability.proficiencies.map((proficiency) => {
              const requirementResults: RequirementResult[] =
                proficiency.requirements.map(
                  (requirement, requirementIndex) => {
                    //requirement object
                    return {
                      focusArea: focusArea.index,
                      capability: capability.index,
                      level: proficiency.level,
                      requirement: requirementIndex + 1,
                      score: 0,
                      questionAssessedCount: 0,
                      scoreRatio: null,
                    }
                  },
                )

              //proficiency object
              return {
                level: proficiency.level,
                requirementResults: requirementResults,
                score: 0,
                questionAssessedCount: 0,
                scoreRatio: null,
              }
            })

          //capability object
          return {
            focusArea: focusArea.index,
            capability: capability.index,
            level: null,
            proficiencyResults: proficiencyResults,
            questionAssessedCount: 0,
            highestPassingLevel: null,
            lowestFailingLevel: null,
            score: 0,
            scoreRatio: null,
          }
        },
      )

      //focusArea object
      return {
        focusArea: focusArea.index,
        level: null,
        capabilityResults: capabilityResults,
        questionAssessedCount: 0,
        highestPassingLevel: null,
        lowestFailingLevel: null,
        score: 0,
        scoreRatio: null,
      }
    })

    //assessment object
    return {
      frameworkId: (assessment as Assessment2).framework._id ?? '',
      level: null,
      score: 0,
      questionAssessedCount: 0,
      scoreRatio: null,
      focusAreaResults: focusAreaResults,
    }
  }

  const updateResult = (questionAnswered2: QuestionAnswered2) => {
    console.log('updateResult with:', questionAnswered2)

    //init result
    if (!assessmentResult.current) {
      assessmentResult.current = initAssessmentResult()
    }

    //quick variables
    const focusAreaId = questionAnswered2.focusArea
    const capabilityId = questionAnswered2.capability
    const levelId = questionAnswered2.level
    const requirementId = questionAnswered2.requirement

    const focusAreaResult = assessmentResult.current.focusAreaResults.find(
      (focusArea) => focusArea.focusArea === focusAreaId,
    )
    const capabilityResult = focusAreaResult?.capabilityResults.find(
      (capability) => capability.capability === capabilityId,
    )
    const proficiencyResult = capabilityResult?.proficiencyResults.find(
      (level) => level.level === levelId,
    )
    const requirementResult = proficiencyResult?.requirementResults.find(
      (requirement) => requirement.requirement === requirementId,
    )

    ////////////////////////
    //data update
    //update requirement
    requirementResult!.score += questionAnswered2.score
    requirementResult!.questionAssessedCount += 1
    requirementResult!.scoreRatio =
      requirementResult!.score / requirementResult!.questionAssessedCount

    //update proficiency
    proficiencyResult!.score += questionAnswered2.score
    proficiencyResult!.questionAssessedCount += 1
    proficiencyResult!.scoreRatio =
      proficiencyResult!.score / proficiencyResult!.questionAssessedCount

    //update capability
    capabilityResult!.questionAssessedCount += 1
    const passingScore = 0.7
    const bottomLevel = capabilityResult!.proficiencyResults.reduce(
      (min, proficiency) => {
        return proficiency.level < min ? proficiency.level : min
      },
      Infinity,
    )
    const capabilityHighestPassingProficiencyLevel =
      findHighestPassingLevelByProficiencies(
        capabilityResult!.proficiencyResults,
        passingScore,
      )
    const capabilityLowestFailingProficiencyLevel =
      findLowestFailingLevelByProficiencies(
        capabilityResult!.proficiencyResults,
        passingScore,
      )

    capabilityResult!.score += questionAnswered2.score
    capabilityResult!.scoreRatio =
      capabilityResult!.score / capabilityResult!.questionAssessedCount

    capabilityResult!.highestPassingLevel =
      capabilityHighestPassingProficiencyLevel
    capabilityResult!.lowestFailingLevel =
      capabilityLowestFailingProficiencyLevel

    ////////////////////////
    //capability estimates
    let capabilityLevel: number | null = null
    if (
      capabilityHighestPassingProficiencyLevel &&
      capabilityLowestFailingProficiencyLevel
    ) {
      //lowest failing > highest passing
      if (
        capabilityLowestFailingProficiencyLevel >
        capabilityHighestPassingProficiencyLevel
      ) {
        //normal case
        capabilityLevel = capabilityHighestPassingProficiencyLevel
      } else {
        //lowest failing >= highest passing
        //abnormal case
        capabilityLevel =
          (capabilityHighestPassingProficiencyLevel +
            capabilityLowestFailingProficiencyLevel) /
          2
      }
    } else if (capabilityHighestPassingProficiencyLevel) {
      //has correct, no wrong
      capabilityLevel = capabilityHighestPassingProficiencyLevel
    } else if (capabilityLowestFailingProficiencyLevel) {
      //no correct, set level lower than lowest failing
      capabilityLevel = capabilityLowestFailingProficiencyLevel - 1
    }
    if (capabilityLevel! < bottomLevel) {
      capabilityLevel = bottomLevel
    }
    capabilityResult!.level = capabilityLevel

    //update focusarea
    //average may not be applicable because randomly assessed low level proficiencies may skew the average
    // focusAreaResult!.level = findAverageLevelForAssessedCapabilities(
    //   focusAreaResult!.capabilityResults,
    // )
    //calcuate the focus area highestPassingLevel by its highestPassingLevel in capabilities
    focusAreaResult!.questionAssessedCount += 1
    focusAreaResult!.score += questionAnswered2.score
    focusAreaResult!.scoreRatio =
      focusAreaResult!.score / focusAreaResult!.questionAssessedCount
    focusAreaResult!.highestPassingLevel =
      focusAreaResult?.capabilityResults.reduce((max, capability) => {
        return capability.highestPassingLevel! > max
          ? capability.highestPassingLevel!
          : max
      }, -Infinity) ?? null

    for (const capability of focusAreaResult!.capabilityResults) {
      if (
        capability.lowestFailingLevel !== null &&
        capability.lowestFailingLevel <
          (focusAreaResult!.lowestFailingLevel ?? Infinity)
      ) {
        focusAreaResult!.lowestFailingLevel = capability.lowestFailingLevel
      }
    }

    //focus area estimates
    let focusAreaLevel: number | null = null
    if (
      focusAreaResult!.highestPassingLevel &&
      focusAreaResult!.lowestFailingLevel
    ) {
      if (
        focusAreaResult!.lowestFailingLevel >
        focusAreaResult!.highestPassingLevel
      ) {
        //normal
        focusAreaLevel = focusAreaResult!.highestPassingLevel
      } else {
        //abnormal
        focusAreaLevel =
          (focusAreaResult!.highestPassingLevel +
            focusAreaResult!.lowestFailingLevel) /
          2
      }
    } else if (focusAreaResult!.highestPassingLevel) {
      focusAreaLevel = focusAreaResult!.highestPassingLevel
    } else if (focusAreaResult!.lowestFailingLevel) {
      focusAreaLevel = focusAreaResult!.lowestFailingLevel - 1
    }
    if (focusAreaLevel! < bottomLevel) {
      focusAreaLevel = bottomLevel
    }
    focusAreaResult!.level = focusAreaLevel

    //revisit uncapped capability estimates based on focus area level
    const assessedCapabilitiesInFocusArea =
      focusAreaResult!.capabilityResults.filter(
        (capability) => capability.questionAssessedCount > 0,
      )
    const averageCapabilityLevel = findAverageLevelForAssessedCapabilities(
      assessedCapabilitiesInFocusArea,
    )!
    for (const capability of assessedCapabilitiesInFocusArea) {
      //update capability level by focus area level
      if (capability.lowestFailingLevel === null) {
        //no fail
        capability.level = Math.max(
          capability.level!,
          Math.ceil(averageCapabilityLevel),
        )
      } else if (capability.highestPassingLevel === null) {
        //no pass
        capability.level = Math.min(
          capability.level!,
          Math.floor(averageCapabilityLevel),
        )
      }
    }

    //update assessment
    assessmentResult.current.score += questionAnswered2.score
    assessmentResult.current.questionAssessedCount += 1
    assessmentResult.current.scoreRatio =
      assessmentResult.current.score /
      assessmentResult.current.questionAssessedCount
    assessmentResult.current.level = findAverageLevelForAssessedFocusAreas(
      assessmentResult.current.focusAreaResults,
    )

    console.log('in progress assessmentResult', assessmentResult.current)
  }

  const calcConfidence = (): number => {
    const allCapabilities = assessmentResult.current?.focusAreaResults.flatMap(
      (focusArea) => focusArea.capabilityResults,
    )
    const allCapabilitiesConfidence = allCapabilities?.map((capability) => {
      const capabilityConfidence: number =
        (capability.lowestFailingLevel === null &&
          capability.level === topLevel) ||
        (capability.highestPassingLevel === null &&
          capability.level === bottomLevel) ||
        (capability.highestPassingLevel !== null &&
          capability.lowestFailingLevel !== null)
          ? 1
          : 0
      return capabilityConfidence
    })
    // console.log('allCapabilitiesConfidence', allCapabilitiesConfidence)
    const confidenceSum = allCapabilitiesConfidence?.reduce(
      (sum, itemValue) => sum + itemValue,
      0,
    )
    const confidenceScore = confidenceSum! / allCapabilitiesConfidence!.length
    console.log('confidence:', confidenceSum, allCapabilitiesConfidence!.length)
    return confidenceScore
  }

  const findHighestLevelInCapabilities = (
    capabilityResults: CapabilityResult[],
  ): number | null => {
    let highestLevel: number | null = null

    //potentially add some special conditions to exclude levels with an extreme rate. e.g. 90% / 10% passing score
    for (const capabilityResult of capabilityResults) {
      if (capabilityResult.questionAssessedCount > 0) {
        if ((capabilityResult.level ?? 0) > (highestLevel ?? 0)) {
          highestLevel = capabilityResult.level!
        }
      }
    }
    return highestLevel
  }
  const findLowestLevelInCapabilities = (
    capabilityResults: CapabilityResult[],
  ): number | null => {
    let lowestLevel: number | null = null

    for (const capabilityResult of capabilityResults) {
      if (
        capabilityResult.questionAssessedCount > 0 &&
        capabilityResult.level
      ) {
        if (lowestLevel === null) {
          lowestLevel = capabilityResult.level
        } else if (capabilityResult.level < lowestLevel) {
          lowestLevel = capabilityResult.level
        }
      }
    }
    return lowestLevel
  }

  //calculate capability level by its proficiencies
  const findHighestPassingLevelByProficiencies = (
    proficiencyResults: ProficiencyResult[],
    passingScore: number,
  ): number | null => {
    let highestPassingLevel: number | null = null

    for (const proficiencyResult of proficiencyResults) {
      if (proficiencyResult.questionAssessedCount > 0) {
        const ratio =
          proficiencyResult.score / proficiencyResult.questionAssessedCount
        if (ratio >= passingScore) {
          if (highestPassingLevel === null) {
            highestPassingLevel = proficiencyResult.level
          } else if (proficiencyResult.level > highestPassingLevel) {
            highestPassingLevel = proficiencyResult.level
          }
        }
      }
    }

    return highestPassingLevel
  }

  //calculate capability level by its proficiencies
  const findLowestFailingLevelByProficiencies = (
    proficiencyResults: ProficiencyResult[],
    passingScore: number,
    // minLevel: number,
  ): number | null => {
    let lowestFailingLevel: number | null = null

    for (const proficiencyResult of proficiencyResults) {
      if (proficiencyResult.questionAssessedCount > 0) {
        const ratio =
          proficiencyResult.score / proficiencyResult.questionAssessedCount
        if (ratio < passingScore) {
          if (lowestFailingLevel === null) {
            lowestFailingLevel = proficiencyResult.level
          } else if (proficiencyResult.level < lowestFailingLevel) {
            lowestFailingLevel = proficiencyResult.level
          }
        }
      }
    }

    return lowestFailingLevel
  }

  const printResultSummary = () => {
    console.log('========assessment summary========')
    console.log('overall level:',assessmentResult.current!.level)
    for (
      let i = 0;
      i < assessmentResult.current!.focusAreaResults.length;
      i++
    ) {
      const focusAreaLevel = assessmentResult.current!.focusAreaResults[i].level
      const capabilityLevels = assessmentResult.current!.focusAreaResults[
        i
      ].capabilityResults.map((capabilityResult) => capabilityResult.level)
      console.log(`${i + 1} ${focusAreaLevel}-${capabilityLevels}`)
    }

    console.log('questions summary')
    for (const level of (assessment as Assessment2).framework.levels) {
      const questionAskedCount = questionsAnswered2.current.filter(
        (question) => question.level === level.level,
      ).length
      console.log(
        `${level.level}: ${questionAskedCount}`,
      )
    }

    console.log('========assessment summary========')
  }

  const findAverageLevelForAssessedCapabilities = (
    capabilityResults: CapabilityResult[],
  ): number | null => {
    let sum = null
    let count = null //assessed capabilities

    for (const capabilityResult of capabilityResults) {
      if (
        capabilityResult.level !== null &&
        capabilityResult.level !== undefined
      ) {
        sum = (sum ?? 0) + capabilityResult.level
        count = (count ?? 0) + 1
      }
    }

    return count ? sum! / count : null
  }

  const findAverageLevelForAssessedFocusAreas = (
    focusAreaResults: FocusAreaResult[],
  ): number | null => {
    let sum = null
    let count = null //assessed capabilities

    for (const focusAreaResult of focusAreaResults) {
      if (
        focusAreaResult.level !== null &&
        focusAreaResult.level !== undefined
      ) {
        sum = (sum ?? 0) + focusAreaResult.level
        count = (count ?? 0) + 1
      }
    }

    return count ? sum! / count : null
  }

  const bottomAssessableLevel = 1

  const adjustLevel = () => {
    let newLevel: number = currentLevel.current
    if (questionsAnswered.current.length === 0) {
      newLevel = getRandomLevel()
    } else if (questionsAnswered.current.length > 0) {
      console.log(
        'questionsAnswered',
        questionsAnswered.current.length,
        questionsAnswered,
      )

      const remainingTime = assessment?.setup.timeLimit
        ? assessment?.setup.timeLimit - (Date.now() - startTime) / 1000
        : Infinity
      console.log('remainingTime', remainingTime)

      let newConfidence: number = confidence

      //if courses are sequential prerequisits of each other
      if ((assessment as Assessment)?.classifiedLevels.length) {
        //get min level from classified levels
        const minLevel =
          (assessment as Assessment)?.classifiedLevels.reduce(
            (min, levelItem) => {
              return levelItem.level < min ? levelItem.level : min
            },
            Infinity,
          ) ?? 0

        const maxLevel =
          (assessment as Assessment)?.classifiedLevels.reduce(
            (max, levelItem) => {
              return levelItem.level > max ? levelItem.level : max
            },
            -Infinity,
          ) ?? 0

        const batchSize = assessment?.setup.questionBatchSize ?? 1
        //handle batch
        if (questionsAnswered.current.length % batchSize === 0) {
          // adjust level
          //get last batch questions
          const lastBatchQuestions = questionsAnswered.current.slice(-batchSize)
          //sum of scores
          const sumOfScores = lastBatchQuestions.reduce(
            (sum, question) => sum + question.score,
            0,
          )
          const correctRatio = sumOfScores / batchSize
          const downThreshold = (
            assessment as Assessment
          )?.classifiedLevels.find(
            (level) => level.level === currentLevel.current,
          )?.downThreshold
          const upThreshold = (assessment as Assessment)?.classifiedLevels.find(
            (level) => level.level === currentLevel.current,
          )?.upThreshold
          if (downThreshold != null && correctRatio < downThreshold) {
            newLevel = currentLevel.current - 1
          } else if (upThreshold != null && correctRatio >= upThreshold) {
            newLevel = currentLevel.current + 1
          } else {
            // keep level
          }

          //adjust confidence
          const deltaConfidence = assessment?.setup.batchConfidence ?? 0
          if (deltaConfidence) {
            if (newLevel < minLevel) {
              newLevel = minLevel
              newConfidence = newConfidence + deltaConfidence
            } else if (newLevel > maxLevel) {
              newLevel = maxLevel
              newConfidence = newConfidence + deltaConfidence
            } else if (newLevel === currentLevel.current) {
              newConfidence = newConfidence + deltaConfidence
            } else {
              newConfidence = 0
            }
          }
        } else {
          //in the middle of a batch
        }

        console.log('newLevel', newLevel)
        console.log('newConfidence', newConfidence)
      } else {
        //courses are not sequential prerequisits of each other
        currentLevel.current = getRandomLevel()
        newLevel = currentLevel.current
      }

      //trigger end
      if (
        assessment?.setup.questionCountLimit &&
        questionsAnswered.current.length >= assessment?.setup.questionCountLimit
      ) {
        //handle all questions done
        console.log('all questions answered')
        console.log('finalLevel', newLevel)
        calcFinalLevel()
        setFinalLevel(currentLevel.current)
      } else if (remainingTime < 0) {
        console.log('time limit reached')
        console.log('finalLevel', newLevel)
        calcFinalLevel()
        setFinalLevel(newLevel)
      } else if (newConfidence >= 1) {
        //handle shortcut confidence
        console.log('level confidence reached')
        console.log('finalLevel', newLevel)
        calcFinalLevel()
        setFinalLevel(newLevel)
      } else {
        //keep going
        console.log('continue')
        setConfidence(newConfidence)
        // setCurrentLevel(newLevel)
        currentLevel.current = newLevel
      }

      // setQuestionCount(questionCount + 1)
    }
  }

  const checkWorkflow = () => {
    // let newLevel: number = currentLevel.current
    if (questionsAnswered2.current.length === 0) {
      // newLevel = getRandomLevel()
    } else if (questionsAnswered2.current.length > 0) {
      // console.log(
      //   'questionsAnswered',
      //   questionsAnswered2.current.length,
      //   questionsAnswered2,
      // )

      const remainingTime = assessment?.setup.timeLimit
        ? assessment?.setup.timeLimit - (Date.now() - startTime) / 1000
        : Infinity
      console.log('remainingTime', remainingTime)

      const newConfidence = calcConfidence()
      //trigger end
      if (
        assessment?.setup.questionCountLimit &&
        questionsAnswered2.current.length >=
          assessment?.setup.questionCountLimit
      ) {
        //handle all questions done
        console.log('all questions answered')
        setFinalLevel(assessmentResult.current?.level ?? 1)
        printResultSummary()
      } else if (remainingTime < 0) {
        console.log('time limit reached')
        setFinalLevel(assessmentResult.current?.level ?? 1)
      } else if (newConfidence >= 1) {
        console.log('confidence reached')
        // setFinalLevel(assessmentResult.current?.level ?? 1)
      }
      //handle shortcut confidence
      //console.log('level confidence reached')
      //calcFinalLevel()
      //}
      else {
        //keep going
        // console.log('continue')
        // setConfidence(newConfidence)
        // currentLevel.current = newLevel
      }

      // setQuestionCount(questionCount + 1)
    }
  }

  const calcFinalLevel = () => {
    const answeredQuestionsByLevel: QuestionAnswered[][] =
      assessment?.courses?.map((level) => []) ?? []
    questionsAnswered.current.forEach((question) => {
      if (!answeredQuestionsByLevel[question.level - 1]) {
        answeredQuestionsByLevel[question.level - 1] = []
      }
      answeredQuestionsByLevel[question.level - 1].push(question)
    })
    console.log('answeredQuestionsByLevel', answeredQuestionsByLevel)

    const correctAnswerRatesByLevel = answeredQuestionsByLevel
      .filter((level) => level.length > 0)
      .map((level) => {
        return {
          level: level[0].level,
          rate: Math.round(
            (100 * level.reduce((sum, question) => sum + question.score, 0)) /
              level.length,
          ),
        }
      })
    console.log('correctAnswerRatesByLevel', correctAnswerRatesByLevel)
  }
  
  // const calcFinalLevel2 = () => {
    // const answeredQuestionsByLevel: QuestionAnswered[][] =
    //   assessment?.courses?.map((level) => []) ?? []
    // questionsAnswered2.current.forEach((question) => {
    //   if (!answeredQuestionsByLevel[question.level - 1]) {
    //     answeredQuestionsByLevel[question.level - 1] = []
    //   }
    //   answeredQuestionsByLevel[question.level - 1].push(question)
    // })
    // console.log('answeredQuestionsByLevel', answeredQuestionsByLevel)
    // const correctAnswerRatesByLevel = answeredQuestionsByLevel
    //   .filter((level) => level.length > 0)
    //   .map((level) => {
    //     return {
    //       level: level[0].level,
    //       rate: Math.round(
    //         (100 * level.reduce((sum, question) => sum + question.score, 0)) /
    //           level.length,
    //       ),
    //     }
    //   })
    // console.log('correctAnswerRatesByLevel', correctAnswerRatesByLevel)
  // }

  const postActivityLog = async (event: string, data: string) => {
    try {
      const request: ActivityLogRequest = {
        candidateId: candidateId,
        assessmentId: assessmentId,
        email: assessment?.email ?? '',
        event: event,
        data: data,
      }
      await API.postActivityLog(request)
    } catch (error) {
      console.log('error', error)
    }
  }

  const warmup = async () => {
    try {
      await API.warmup()
    } catch (error) {
      console.log('error', error)
    }
  }

  const postSubmissionStart = async (candidateId: string, debug?: boolean) => {
    if (version.current === 1) {
      await API.postSubmissionStart(candidateId, debug)
    } else if (version.current === 2) {
      await API.postSubmissionStartV2(candidateId, debug)
    }
  }

  const postSubmissionStatus = async (candidateId: string) => {
    if (version.current === 1) {
      return await API.postSubmissionStatus(candidateId)
    } else if (version.current === 2) {
      return await API.postSubmissionStatusV2(candidateId)
    }
    return false
  }

  const providerValue = {
    candidateId,
    setCandidateId,
    assessmentId,
    setAssessmentId,
    assessment,
    setAssessment,
    progress,
    setProgress,
    incrementProgress,
    courses,
    setCourses,
    questions,
    setQuestions,
    questions2,
    setQuestions2,
    questionsAnswered,
    // setQuestionsAnswered,
    selfAssessedLevel,
    setSelfAssessedLevel,
    currentLevel,
    // setCurrentLevel,
    scores,
    setScores,
    addScore,
    getSelfAssessmentQuestion,
    currentQuestion,
    setCurrentQuestion,
    finalLevel,
    setFinalLevel,
    submitAnswer,
    submitSelfAssessment,
    startTest,
    endTest,
    confidence,
    setConfidence,
    // questionCount,
    // setQuestionCount,
    updateCurrentQuestion,
    adjustLevel,
    postActivityLog,
    step,
    setStep,
    submitted,
    setSubmitted,
    startTime,
    setStartTime,
    isPublic,
    setIsPublic,
    candidate,
    setCandidate,
    warmup,
    version,
    currentQuestion2,
    setCurrentQuestion2,
    questionsAnswered2,
    // setQuestionsAnswered2,
    checkWorkflow,
    assessmentResult,
    updateResult,
    updateCurrentQuestion2,
    postSubmissionStart,
    postSubmissionStatus,
    autoAnswerRate,
    setAutoAnswerRate,
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
