import './App.css'
import { DataProvider, useDataContext } from './context/DataProvider'
import { AxiosClient } from './apis/configs/AxiosClient'
import { LoadingProvider } from './context/LoadingProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Preloader } from './context/Preloader'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import SelfAssessment from './pages/SelfAssessment'
import Questions from './pages/Questions'
import End from './pages/End'
import Support from './pages/Support'
import DoneBefore from './pages/DoneBefore'
import { useEffect, useState } from 'react'

interface TimerProps {
  initialSeconds: number
}

const Timer: React.FC<TimerProps> = ({ initialSeconds }) => {
  const [seconds, setSeconds] = useState(0)
  const { assessment, startTime } = useDataContext()

  useEffect(() => {
    if (startTime) {
      setSeconds(assessment?.setup.timeLimit ?? 0)
    }
  }, [assessment?.setup.timeLimit, startTime])

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [seconds])

  return <div>{seconds}</div>
}

const AppRoutes = () => {
  const { step, assessment } = useDataContext()
  return (
    <>
      {/* <Timer initialSeconds={assessment?.setup.timeLimit ?? 0} /> */}
      <Routes>
        <Route path="/support" element={<Support />} />
        <Route
          path="/*"
          element={
            <>
              {step === 0 && <Home />}
              {step === 1 && <SelfAssessment />}
              {step === 2 && <Questions />}
              {step === 3 && <End />}
              {step === 4 && <DoneBefore />}
            </>
          }
        />
      </Routes>
    </>
  )
}
function App() {
  return (
    <BrowserRouter>
      <AxiosClient>
        <DataProvider>
          <LoadingProvider>
            <Preloader>
              <div className="App">
                <ToastContainer limit={99} />
                <AppRoutes />
              </div>
            </Preloader>
          </LoadingProvider>
        </DataProvider>
      </AxiosClient>
    </BrowserRouter>
  )
}

export default App
