import Header from '../components/Header/HeaderBanner'
import Footer from '../components/Footer'
import ProgressIndicator from '../components/ProgressIndicator/ProgressIndicator'
import { useDataContext } from '../context/DataProvider'
import { useCallback, useEffect, useState } from 'react'
import { ActivityLogEvent } from '../apis/requests/activity-log.request'
import Spinner from '../components/Spinner'
import { SubmitUserInfo } from '../apis/entities/candidate.entity'
import { API } from '../apis/API'
import { StyleUtil } from '../utils/StyleUtil'

export default function End() {
  const {
    assessment,
    candidateId,
    postActivityLog,
    submitSelfAssessment,
    assessmentResult,
    isPublic,
  } = useDataContext()
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submitTest = useCallback(async () => {
    try {
      setIsLoading(true)
      await submitSelfAssessment()
      setIsSubmitted(true)
    } catch (error) {
      console.error(error)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [submitSelfAssessment])

  useEffect(() => {
    if (isPublic) {
    } else {
      if (!isSubmitted) {
        submitTest()
      }
    }
  }, [assessment, submitTest, isSubmitted])

  // analytics
  useEffect(() => {
    if (candidateId) {
      // console.log('candidateId', candidateId)
      console.log('assessmentResult', assessmentResult.current)
      console.log('focus area count:' + assessmentResult.current?.focusAreaResults.length)
      console.log('capability count:' + assessmentResult.current?.focusAreaResults.flatMap((focusAreaResult) => focusAreaResult.capabilityResults).length)
      console.log('requirement count:' + assessmentResult.current?.focusAreaResults.flatMap((focusAreaResult) => focusAreaResult.capabilityResults.flatMap((capabilityResult) => capabilityResult.proficiencyResults.flatMap(
        (proficiencyResult) => proficiencyResult.requirementResults))).length)
      postActivityLog(ActivityLogEvent.visit, window.location.href)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId])

  const [userInfo, setUserInfo] = useState<SubmitUserInfo>({
    firstName: '',
    lastName: '',
    email: '',
    industry: '',
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target

    setUserInfo((prevUserInfo) => ({ ...prevUserInfo, [name]: value }))
  }
  console.log('userInfo', userInfo)
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    // Basic email validation using a regular expression
    const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

    if (!emailPattern.test(userInfo.email)) {
      setErrorMessage('Invalid email format')
      return
    }
    setErrorMessage('')

    // You can perform further actions like sending the data to a server here
    console.log('User Info:', userInfo)

    await API.updateCandidate(
      candidateId,
      userInfo.firstName,
      userInfo.lastName,
      userInfo.email,
      userInfo.industry,
    )
    submitTest()

    // Reset the form
    // setUserInfo({ firstName: '', lastName: '', email: '' });
  }

  const industries = [
    'Accommodation and food services',
    'Administrative and support services',
    'Agriculture, forestry and fishing',
    'Arts and recreation services',
    'Construction',
    'Education and training', //(private)
    'Electricity, gas, water and waste services',
    'Health care and social assistance (private)',
    'Information media and telecommunications',
    'Manufacturing',
    'Mining',
    'Professional, scientific and technical services',
    'Public administration and safety', //(private)
    'Rental, hiring and real estate services',
    'Retail trade',
    'Transport, postal and warehousing',
    'Wholesale trade',
    'Other services',
  ]

  return (
    <div className="h-screen">
      <div className="p-8 bg-white space-y-8 flex flex-col items-center">
        <div className="relative md:max-w-[800px] lg:max-w-[1000px] xl:max-w-[1200px] ">
          <Header
            media={'https://cdn.mentem.co/assessment.jpg'}
            title={assessment?.title ?? ''}
            description={'Develop. Scale. Grow'}
          />
          <div className="flex justify-center">
            <ProgressIndicator />
          </div>
          {/* form */}
          {!isLoading && isPublic && !isSubmitted && (
            <div className="flex flex-column align-center justify-center">
              <div className="text-center md:w-[600px] sm:max-w-[480px]">
                <h1 className="text-4xl font-bold my-4">
                  We’re calculating your results
                </h1>
                <div className="p-4">
                  Let us know where to email your results to
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-row gap-2">
                    <div className="grow">
                      {/* <label htmlFor="firstName">First Name:</label> */}
                      <input
                        className="block border border-grey-light w-full p-3 rounded mb-4"
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="First name (Optional)"
                        value={userInfo.firstName}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* <label htmlFor="lastName">Last Name:</label> */}
                    {/* <div className='grow'>
                      <input
                        className="block border border-grey-light w-full p-3 rounded mb-4"
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Last name"
                        value={userInfo.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </div> */}
                  </div>
                  <div>
                    {/* <label htmlFor="email">Email:</label> */}
                    <input
                      className="block border border-grey-light w-full p-3 rounded mb-4"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email address"
                      value={userInfo.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <select
                    className="block border border-grey-light w-full p-3 rounded mb-4"
                    id="industry"
                    name="industry"
                    value={userInfo.industry}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Please select your industry</option>
                    {industries.map((industry) => (
                      <option key={industry} value={industry}>
                        {industry}
                      </option>
                    ))}
                  </select>
                  <div>
                    <button type="submit" className={StyleUtil.buttonAction}>
                      Submit
                    </button>
                  </div>
                  <div className="m-8">
                    You will receive a personal report from{' '}
                    <a
                      className="text-purple-600"
                      href="mailto:hello@mentem.co"
                    >
                      hello@mentem.co
                    </a>{' '}
                    - please check your email inbox (or junk mail, just in case)
                  </div>
                </form>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </div>
            </div>
          )}
          {isLoading && (
            <>
              <div className="flex justify-center">
                <Spinner />
              </div>
              <div className="flex justify-center">Submitting the test...</div>
            </>
          )}

          {/* thank you message */}
          {!isLoading && isSubmitted && !hasError && (
            <>
              <h1 className="text-4xl font-bold my-4">
                Thanks for completing the test!
              </h1>
              <p className="my-4">
                Thank you for taking the time to complete this placement test.
                Your answers will help in planning Mentem programs for{' '}
                {assessment?.client}. If you have any questions about this test
                or want to learn more about Mentem and our programs, you can
                contact us at{' '}
                <a className="text-purple-600" href="mailto:hello@mentem.co">
                  hello@mentem.co
                </a>{' '}
                or visit{' '}
                <a
                  className="text-purple-600"
                  href="https://www.mentem.co"
                  target="_blank"
                  rel="opener noreferrer"
                >
                  https://www.mentem.co
                </a>
                .
              </p>
            </>
          )}
          {hasError && (
            <>
              <h1 className="text-4xl font-bold my-4">
                Unable to submit the test
              </h1>
              <p className="my-4">
                We apologize for the inconvenience, but it seems that we are
                currently experiencing technical difficulties that prevent us
                from processing the test at this time.
              </p>
            </>
          )}
          {!isPublic && (
            <>
              <p className="my-4 ">
                <span className="font-bold">Privacy: </span>This test does not
                capture any identifying information. Your recommended starting
                level will be shared with your organization, and your responses
                may be used to help Mentem improve and develop the test in the
                future. See our master{' '}
                <a
                  className="text-purple-600"
                  href="https://www.mentem.co/privacy"
                  target="_blank"
                  rel="opener noreferrer"
                >
                  privacy policy
                </a>{' '}
                for more information.
              </p>
            </>
          )}
          {isPublic && (
            <>
              <p className="my-4 ">
                <span className="font-bold">Privacy: </span>
                Your personal information will only be used for the purpose of
                this request. Specifically, your email address will be used to
                deliver your personal report. You are not giving Mentem consent
                to send follow-up communication beyond this report. We do not
                share your data with third parties without your consent. For
                more information on how we handle your data, see our master{' '}
                <a
                  className="text-purple-600"
                  href="https://www.mentem.co/privacy"
                  target="_blank"
                  rel="opener noreferrer"
                >
                  privacy policy
                </a>{' '}
                for more information.
              </p>
            </>
          )}
          <Footer />
        </div>
      </div>
    </div>
  )
}
