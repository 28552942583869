import apiClient from './configs/AxiosClient'
import { Assessment, Assessment2 } from './entities/assessment.entity'
import { Candidate } from './entities/candidate.entity'
import { Question2 } from './entities/question.entity'
import { ActivityLogRequest } from './requests/activity-log.request'
import { AnswerRequestV2 } from './requests/answer-v2.request'
import { AnswerRequest } from './requests/answer.request'

export const TEST_CANDIDATE_ID = 'ac469c81-0470-407b-9536-446da9b43991'

export const API = {
  getAssessment: async (
    candidateId: string,
  ): Promise<Assessment | Assessment2 | undefined> => {
    let url = `/candidates/${candidateId}/assessment`
    if (process.env.REACT_APP_CI === 'true') {
      url = `/candidates/${TEST_CANDIDATE_ID}/assessment?limit=60&ci=1`
    }
    const response: any = await apiClient.get(url)
    return response.data
  },
  getAssessmentById: async (
    assessmentId: string,
  ): Promise<Assessment | Assessment2 | undefined> => {
    let url = `/assessments/${assessmentId}?cache=0`
    const response: any = await apiClient.get(url)
    return response.data
  },
  getAssessmentBySlug: async (
    slug: string,
  ): Promise<Assessment | Assessment2 | undefined> => {
    let url = `/assessments/slug/${slug}?cache=0`
    const response: any = await apiClient.get(url)
    return response.data
  },
  // getAssessmentBySlug2: async (
  //   slug: string,
  // ): Promise<Assessment2 | undefined> => {
  //   let url = `v2/assessments/slug/${slug}?cache=0`
  //   const response: any = await apiClient.get(url)
  //   return response.data
  // },
  // getAssessmentById2: async (
  //   assessmentId: string,
  // ): Promise<Assessment2 | undefined> => {
  //   let url = `/v2/assessments/${assessmentId}?cache=0`
  //   const response: any = await apiClient.get(url)
  //   return response.data
  // },
  getQuestionsByFrameworkId: async (
    frameworkId: string,
  ): Promise<Question2[] | undefined> => {
    let url = `/v2/questions/${frameworkId}`
    const response: any = await apiClient.get(url)
    return response.data
  },
  postAnswer: async (request: AnswerRequest): Promise<any> => {
    const response: any = apiClient.post('/answers', request)
    return response.data
  },
  postAnswers: async (requests: AnswerRequest[]): Promise<any> => {
    const response: any = apiClient.post('/answers/batch', requests)
    return response.data
  },
  postAnswerV2: async (request: AnswerRequestV2): Promise<any> => {
    const response: any = apiClient.post('/v2/answers', request)
    return response.data
  },
  postAnswersV2: async (requests: AnswerRequestV2[]): Promise<any> => {
    const response: any = apiClient.post('/v2/answers/batch', requests)
    return response.data
  },
  postActivityLog: async (request: ActivityLogRequest): Promise<void> => {
    await apiClient.post('/activity-logs', request)
  },
  postSubmissionStart: async (
    candidateId: string,
    debug: boolean = false,
  ): Promise<boolean> => {
    const response: any = await apiClient.post('/submissions/start', {
      candidateId: candidateId,
      debug: debug,
    })
    console.log(response.data)
    return response.data.success
  },
  postSubmissionStatus: async (candidateId: string): Promise<boolean> => {
    const response: any = await apiClient.post('/submissions/status', {
      candidateId: candidateId,
    })
    const submissionId = response.data.submissionId
    const startedAt = response.data.startedAt
    const submittedAt = response.data.submittedAt
    console.log(
      `submissionId: ${submissionId}, startedAt: ${startedAt}, submittedAt: ${submittedAt}`,
    )
    return submittedAt !== null
  },
  postSubmissionSubmit: async (
    candidateId: string,
    finalLevel: number,
    debug: boolean = false,
    report: boolean = true,
  ): Promise<boolean> => {
    const response: any = await apiClient.post('/submissions/submit', {
      candidateId: candidateId,
      finalLevel: finalLevel,
      debug: debug,
      report: report,
    })
    console.log(response.data)
    return response.data.success
  },
  postSubmissionStartV2: async (
    candidateId: string,
    debug: boolean = false,
  ): Promise<boolean> => {
    const response: any = await apiClient.post('/v2/submissions/start', {
      candidateId: candidateId,
      debug: debug,
    })
    console.log(response.data)
    return response.data.success
  },
  postSubmissionStatusV2: async (candidateId: string): Promise<boolean> => {
    const response: any = await apiClient.post('/v2/submissions/status', {
      candidateId: candidateId,
    })
    const submissionId = response.data.submissionId
    const startedAt = response.data.startedAt
    const submittedAt = response.data.submittedAt
    console.log(
      `submissionId: ${submissionId}, startedAt: ${startedAt}, submittedAt: ${submittedAt}`,
    )
    return submittedAt !== null
  },
  postSubmissionSubmitV2: async (
    candidateId: string,
    result: any,
    debug: boolean = false,
    report: boolean = true,
  ): Promise<boolean> => {
    const response: any = await apiClient.post('/v2/submissions/submit', {
      candidateId: candidateId,
      result: result,
      debug: debug,
      report: report,
    })
    console.log(response.data)
    return response.data.success
  },
  createCandidate: async (assessmentId: string): Promise<Candidate> => {
    const response: any = await apiClient.post('/candidates', {
      assessmentId: assessmentId,
    })
    console.log(response.data)
    return response.data
  },
  updateCandidate: async (
    candidateId: string,
    firstName: string,
    lastName: string,
    email: string,
    industry: string,
  ): Promise<Candidate> => {
    const response: any = await apiClient.patch(`/candidates/${candidateId}`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      industry: industry,
    })
    console.log(response.data)
    return response.data
  },
  warmup: async (): Promise<any> => {
    const response: any = await apiClient.post('/warmup')
    console.log(response.data)
    return response.data
  },
}
