import React from 'react'
import mentem from '../../images/mentem.png'
// interface HeaderProps {
//   media: string
//   title: string
//   subheading: string
//   description: string
// }
import logo from '../../images/logo.svg'
import './HeaderBanner.css'

const HeaderBanner: React.FC<any> = ({ media, title, description }) => {
  return (
    <div className="header-container">
      <div
        className="hero-image"
        style={{ backgroundImage: `url('${media}')` }}
      >
        <div className="header-content">
          <div className="shade-container absolute">
            <img className="h-full" src={logo} alt="logo" />
          </div>
          <div className="foreground-container">
            <img className="w-32" src={mentem} alt="mentem by unsw" />
            <div className="hero-text">
              <div className="header-title">
                <span>{title}</span>
              </div>
              <div className="font-bold header-description">
                <span className="text-[18px]">{description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderBanner
