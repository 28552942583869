const delayAnswer = 60
const delayNext = 60
let correctRate = 0.5 //0-1

export const AutomationUtils = {

  setCorrectRate: (rate:number) => {
    correctRate = rate; // This function allows you to set the 'correctRate' externally
  },

  start: (targetCorrectRate:number) => {
    console.log('AutomationUtils.start')
    correctRate = targetCorrectRate;
    // timeout to wait for page to load
    setTimeout(() => {
      AutomationUtils.clickMultipleChoice()
    }, delayAnswer)
  },
  clickMultipleChoice: () => {
    // click multiple choice item randomly
    const choiceItems = document.querySelectorAll<HTMLDivElement>('.mc-item')
    if (choiceItems.length === 0) {
      console.log('no choice items')
      return
    }
    const correct = Math.random() < correctRate
    console.log('auto answer correct: ', correct)
    const randomIndex = correct ? 0 : Math.floor(Math.random() * (choiceItems.length - 1) + 1) // 1 to length - 1
    choiceItems[randomIndex].click()

    setTimeout(() => {
      AutomationUtils.clickNextButton()
    }, delayNext)
  },
  clickNextButton: () => {
    const nextButton =
      document.querySelector<HTMLButtonElement>('.mentem-button')
    nextButton?.click()

    // loop
    setTimeout(() => {
      AutomationUtils.clickMultipleChoice()
    }, delayAnswer)
  },
}
