import React from 'react'
import './ProgressIndicator.css'
import start from './start.png'
import end from './end.png'
import indicator from './indicator.png'
import { useDataContext } from '../../context/DataProvider'

const ProgressIndicator: React.FC<{
  ratio?: number
}> = ({ ratio }) => {
  const { progress } = useDataContext()
  const displayedProgress = Math.max(0, Math.min(ratio ?? progress, 1))

  return (
    <div
      className="progress-bar"
      // style={{'background': 'red'}}
      style={
        { '--progress': `${displayedProgress * 100}%` } as React.CSSProperties
      }
      // style={{'background': `${normalizedProgress*100}%` }}
    >
      {/* dynamic indicator */}
      <div className="invisible-track">
        <div
          className="indicator"
          style={
            {
              '--display': `${
                displayedProgress === 0 || displayedProgress === 1
                  ? 'none'
                  : 'block'
              }`,
            } as React.CSSProperties
          }
        >
          <img src={indicator} alt="start" />
        </div>
      </div>
      {/* dynamic track */}
      <div className="track-background">
        <div className="track-foreground" />
      </div>
      {/* icons */}
      <div className="start-end">
        <div>
          <img src={start} alt="start" />
        </div>
        <div className="space" />
        <div>
          <img src={displayedProgress === 1 ? start : end} alt="end" />
        </div>
      </div>
    </div>
  )
}

export default ProgressIndicator
