import { useCallback, useEffect, useState } from 'react'
import HeaderBanner from '../components/Header/HeaderBanner'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom'
import { toastInfo } from '../utils/toast'
import { goToTop } from '../utils/interactions'
import ProgressIndicator from '../components/ProgressIndicator/ProgressIndicator'
import { useDataContext } from '../context/DataProvider'
import { API } from '../apis/API'
import { getUrlParam } from '../utils/stringUtils'
import { StyleUtil } from '../utils/StyleUtil'
import { ActivityLogEvent } from '../apis/requests/activity-log.request'
import { RouteUtils } from '../utils/routeUtils'
import Spinner from '../components/Spinner'
import { Assessment, Assessment2 } from '../apis/entities/assessment.entity'
import { Helmet } from 'react-helmet'

export default function Home() {
  const {
    candidateId,
    setCandidateId,
    setAssessmentId,
    assessment,
    setAssessment,
    setCourses,
    setQuestions,
    setQuestions2,
    incrementProgress,
    startTest,
    courses,
    postActivityLog,
    setStep,
    setSubmitted,
    adjustLevel,
    setIsPublic,
    isPublic,
    setCandidate,
    version,
    postSubmissionStart,
    postSubmissionStatus,
    setAutoAnswerRate,
  } = useDataContext()
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const [isloading, setIsLoading] = useState(false)

  const handleSubmitClick = async () => {
    if (isloading) return
    setIsLoading(true)
    let _candidateId = candidateId
    if (isChecked) {
      if (isPublic) {
        // create candidate
        console.log('create candidate', assessment?.id)
        console.log('assessment', assessment)
        const candidate = await API.createCandidate(assessment?.id ?? '')
        setCandidate(candidate)
        setCandidateId(candidate.id)
        _candidateId = candidate.id
      }
      // no need to await here
      postSubmissionStart(_candidateId)
      startTest()
      incrementProgress()
      goToTop()
      // console.log('assessment', assessment)
      if (((assessment as Assessment)?.classifiedLevels.length ?? 0) > 0) {
        setStep(1)
      } else {
        adjustLevel()
        setStep(2)
      }
    } else {
      console.log('not checked')
      toastInfo(`Please accept the terms and conditions to continue`)
    }
  }

  const fetchCandidateAssessment = useCallback(
    async (candidateId: string) => {
      if (candidateId) {
        try {
          const assessment = await API.getAssessment(candidateId)
          const submitted = await postSubmissionStatus(candidateId)
          console.log('submitted', submitted)
          setSubmitted(submitted)

          if (assessment) {
            version.current = assessment.version
            setAssessment(assessment)
            // TODO: remove duplicate data
            setAssessmentId(assessment.id)
            setCourses(assessment.courses)
            if (version.current === 2) {
              const frameworkId = (assessment as Assessment2).framework._id
              const questions2 = await API.getQuestionsByFrameworkId(
                frameworkId,
              )
              setQuestions2(questions2 ?? [])
            } else if (assessment as Assessment) {
              setQuestions((assessment as Assessment).questions)
            }

            if (submitted) {
              // don't check for CI
              if (process.env.REACT_APP_CI === 'true') return
              setStep(4)
            } else {
              // console.log('assessment loaded', assessment)
            }
          } else {
            navigate(RouteUtils.Support)
          }
        } catch (error) {
          navigate(RouteUtils.Support)
          console.error('error', error)
        }
      }
    },
    [
      navigate,
      postSubmissionStatus,
      setAssessment,
      setAssessmentId,
      setCourses,
      setQuestions,
      setQuestions2,
      setStep,
      setSubmitted,
      version,
    ],
  )

  const fetchAssessmentBySlug = useCallback(
    async (slug: string) => {
      if (slug) {
        try {
          let assessment: Assessment | Assessment2 | undefined
          // if (version.current === 2) {
          // assessment = await API.getAssessmentBySlug2(slug)
          // } else {
          assessment = await API.getAssessmentBySlug(slug)
          // }
          if (assessment) {
            version.current = assessment.version
            setAssessment(assessment)
            // TODO: remove duplicate data
            setAssessmentId(assessment.id)
            setCourses(assessment.courses)
            console.log('assessment', assessment)
            if (version.current === 2) {
              const frameworkId = (assessment as Assessment2).framework?._id
              const questions2 = await API.getQuestionsByFrameworkId(
                frameworkId,
              )
              setQuestions2(questions2 ?? [])
            } else if (assessment as Assessment) {
              setQuestions((assessment as Assessment).questions)
            }
          } else {
            navigate(RouteUtils.Support)
          }
        } catch (error) {
          navigate(RouteUtils.Support)
          console.error('error', error)
        }
      }
    },
    [
      navigate,
      setAssessment,
      setAssessmentId,
      setCourses,
      setQuestions,
      setQuestions2,
      version,
    ],
  )

  const fetchAssessmentById = useCallback(
    async (aid: string) => {
      if (aid) {
        try {
          let assessment: Assessment | Assessment2 | undefined
          // if (version.current === 2) {
          //   assessment = await API.getAssessmentById2(aid)
          // } else {
          assessment = await API.getAssessmentById(aid)
          // }
          if (assessment) {
            version.current = assessment.version
            setAssessment(assessment)
            // TODO: remove duplicate data
            setAssessmentId(assessment.id)
            setCourses(assessment.courses)
            console.log('assessment', assessment)
            if (version.current === 2) {
              const frameworkId = (assessment as Assessment2).framework?._id
              const questions2 = await API.getQuestionsByFrameworkId(
                frameworkId,
              )
              setQuestions2(questions2 ?? [])
            } else if (assessment as Assessment) {
              setQuestions((assessment as Assessment).questions)
            }
          } else {
            navigate(RouteUtils.Support)
          }
        } catch (error) {
          navigate(RouteUtils.Support)
          console.error('error', error)
        }
      }
    },
    [
      navigate,
      setAssessment,
      setAssessmentId,
      setCourses,
      setQuestions,
      setQuestions2,
      version,
    ],
  )

  const isReady = (): boolean => {
    return courses?.length > 0
  }

  const pathnameParts = window.location.pathname
    .split('/')
    .filter((part) => part !== '') // Split the pathname and remove empty parts
  let slug = ''
  const lastPart = pathnameParts[pathnameParts.length - 1]
  if (lastPart) {
    const qIndex = lastPart.indexOf('?')
    if (qIndex !== -1) {
      slug = lastPart.substring(0, qIndex)
    } else {
      slug = lastPart
    }
  }
  // if (lastPart?.charAt(0) !== '?') {
  //   slug = pathnameParts[pathnameParts.length - 1]
  // }

  // enforce render once
  useEffect(() => {
    let candidateId = getUrlParam('cid')
    let assessmentId = getUrlParam('aid')
    let autoAnswerRate = parseFloat(getUrlParam('auto'))

    if (!isNaN(autoAnswerRate)) {
      console.log('autoAnswerRate', autoAnswerRate)
      setAutoAnswerRate(autoAnswerRate)
    }
    // let v = Number(getUrlParam('v') || 1)

    // let v = 1
    // if (pathnameParts.length > 1) {
    //   const secondLastPath = pathnameParts[pathnameParts.length - 2]
    //   if (secondLastPath?.charAt(0) === 'v') {
    //     v = Number(secondLastPath.replace('v', ''))
    //   }
    // }

    // console.log('version', version.current)
    if (candidateId) {
      // console.log('candidateId', candidateId)
      setIsPublic(false)
      setCandidateId(candidateId)
      fetchCandidateAssessment(candidateId)
    } else if (slug) {
      console.log('slug', slug)
      setIsPublic(true)
      fetchAssessmentBySlug(slug)
    } else if (assessmentId) {
      console.log('assessmentId', assessmentId)
      setIsPublic(true)
      fetchAssessmentById(assessmentId)
    } else {
      navigate(RouteUtils.Support)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // analytics
  useEffect(() => {
    if (candidateId && assessment?.email) {
      postActivityLog(ActivityLogEvent.visit, window.location.href)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId, assessment])

  const testMinutes = Math.ceil((assessment?.setup.timeLimit ?? 300) / 60)
  const testMinuteCopy =
    testMinutes > 1 ? `${testMinutes} minutes` : `${testMinutes} minute`

  return (
    <div className="h-screen">
      <div className="p-8 bg-white space-y-8 flex flex-col items-center">
        <div className="relative md:max-w-[800px] lg:max-w-[1000px] xl:max-w-[1200px] w-full">
          <HeaderBanner
            media={'https://cdn.mentem.co/assessment.jpg'}
            title={assessment?.title ?? ''}
            description={'Develop. Scale. Grow'}
          />
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content="Develop. Scale. Grow" />
            <title>{assessment?.title || 'Mentem Assessment'}</title>
            <link rel="canonical" href={`https://assess.mentem.co/${slug}`} />
          </Helmet>
          {isReady() && (
            <>
              <div className="flex justify-center">
                <ProgressIndicator />
              </div>
              <h1 className="text-4xl font-bold my-4">
                Welcome to{' '}
                {assessment?.title ??
                  assessment?.client + ' ' + assessment?.program + ' Test'}
              </h1>
              <p className="my-4">
                This short test is designed to gauge your current proficiency in{' '}
                {assessment?.program}
                {assessment?.client && !assessment.setup.allowSignup
                  ? ` and help ${assessment?.client} group learners
              into courses`
                  : ''}
                . You will take a maximum of{' '}
                {assessment?.setup.questionCountLimit ?? 10} multiple-choice
                questions and the test should take no longer than{' '}
                {testMinuteCopy}.
              </p>
              <p className="my-4">
                This is a smart test - your responses to questions will
                determine what questions you get next. This means you need a
                stable internet connection for the next {testMinuteCopy}, and
                can't click to go back once you've submitted an answer.
              </p>
              <p className="my-4">
                Please answer each question to the best of your ability.
                Remember, this is not a graded test, but a tool to identify your
                strengths and opportunities for learning.
              </p>

              <div className="flex flex-row items-center align-middle">
                <label className="align-middle flex justify-center align-center">
                  <input
                    data-testid="checkbox-terms"
                    type="checkbox"
                    className="text-blue-600"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </label>

                <span className="ml-2">
                  I confirm I understand and agree to these instructions.
                </span>
              </div>

              <div className="flex justify-center my-8">
                <button
                  data-testid="btn-begin-test"
                  className={StyleUtil.buttonAction}
                  onClick={handleSubmitClick}
                >
                  Begin test
                </button>
              </div>
            </>
          )}
          {!isReady() && (
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  )
}
