import React from 'react'
import { toastInfo } from '../utils/toast'
import { toast } from 'react-toastify'
import { Answer } from '../apis/entities/answer.entity'
import { StyleUtil } from '../utils/StyleUtil'
import { useDataContext } from '../context/DataProvider'

interface Props {
  callback: (questionId: string, answerId: string) => void
  questionText: string
  answers: Answer[]
  questionNumber: number
  questionId?: string
  submitText?: string
}

const MultipleChoiceCard: React.FC<Props> = ({
  callback,
  questionText,
  answers,
  questionNumber,
  questionId,
  submitText = 'Submit',
}) => {
  const { submitAnswer } = useDataContext()
  const [answerId, setAnswerId] = React.useState<string>()
  const submittedQuestionId = questionId ?? ''

  function onClickMultipleChoiceItem(
    questionId: string,
    answerId: string,
  ): void {
    setAnswerId(answerId)
    // console.log('onClickMultipleChoiceItem', questionId, answerId)
  }

  async function onClickMultipleChoiceSubmit(
    questionId: string,
    answerId: string | undefined,
  ) {
    if (answerId !== undefined) {
      await submitAnswer(questionId, questionNumber, answerId)
      toast.dismiss()
      callback(questionId, answerId)
      setAnswerId(undefined)
    } else {
      toastInfo(`Please select an answer to continue`)
    }
  }

  return (
    <div className="card text-center">
      <div className="card-body">
        <p className="mc-question">{questionText}</p>
        <div className="mc-divider-box pt-6 pb-4">
          <div className="mc-divider"></div>
        </div>

        {answers.map((answer, index) => (
          <div className="mc-answers" id="mc-answers" key={`chc-${index}`}>
            <div
              data-testid="mc-item"
              className="mc-item"
              onClick={() =>
                onClickMultipleChoiceItem(submittedQuestionId, answer.id)
              }
            >
              <input
                type="radio"
                id={`${answer.id}`}
                name={`group-${questionId}`}
                value="Insert correct answer to question 1"
                data-correct="yes"
                data-result="Insert feedback a learner sees if they select this option"
                checked={answerId === answer.id}
                className="mc-radio"
                readOnly
              />
              <label className="mc-label" htmlFor={`${answer.id}`}>
                {answer.text}
              </label>
            </div>
          </div>
        ))}

        <br />

        <div className="flex justify-center">
          <button
            data-testid="btn-submit"
            className={StyleUtil.buttonAction}
            onClick={() =>
              onClickMultipleChoiceSubmit(submittedQuestionId, answerId)
            }
          >
            {submitText}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MultipleChoiceCard
