import Header from '../components/Header/HeaderBanner'
import Footer from '../components/Footer'

export default function Support() {
  return (
    <div className="bg-black h-screen">
      <div className="p-8 bg-white space-y-8">
        <Header
          media={'https://cdn.mentem.co/assessment.jpg'}
          title={''}
          description={'Develop. Scale. Grow'}
        />
        <p className="font-bold my-4">
          It looks like you're trying to access a test. Please follow the unique
          link provided in the email you received.
        </p>
        <p className="font-bold my-4">
          If you're having issues accessing the link you were provided, please
          contact{' '}
          <a className="text-purple-600" href="mailto:support@mentem.co">
            support@mentem.co
          </a>
        </p>
        <p className="font-bold my-4">
          If you're looking for more general information on Mentem or a demo of
          our learner experience, please check out{' '}
          <a
            className="text-purple-600"
            href="https://mentem.co"
            target="_blank"
            rel="opener noreferrer"
          >
            https://mentem.co
          </a>{' '}
          or{' '}
          <a
            className="text-purple-600"
            href="https://demo.mentem.co"
            target="_blank"
            rel="opener noreferrer"
          >
            https://demo.mentem.co
          </a>
        </p>
      </div>
      <Footer />
    </div>
  )
}
