export enum ActivityLogEvent {
  visit = 'visit',
  answer = 'answer',
}

export interface ActivityLogRequest {
  candidateId: string
  assessmentId: string
  email: string
  event: string
  data: string
}
