import Footer from '../components/Footer'
import MultipleChoiceCard from '../components/MultipleChoiceCard'
import ProgressIndicator from '../components/ProgressIndicator/ProgressIndicator'
import { useDataContext } from '../context/DataProvider'
import { useEffect } from 'react'
import { ActivityLogEvent } from '../apis/requests/activity-log.request'
import Accordion from '../components/Accordion'

export default function SelfAssessment() {
  const {
    incrementProgress,
    getSelfAssessmentQuestion,
    setSelfAssessedLevel,
    currentLevel,
    // setCurrentLevel,
    candidateId,
    postActivityLog,
    setStep,
  } = useDataContext()

  const next = (questionId: string, answerId: string | undefined) => {
    if (answerId !== undefined) {
      console.log(
        'self assessed questionId:',
        questionId,
        ' ',
        'answerId:',
        answerId,
      )
      const level = parseInt(answerId) + 1
      setSelfAssessedLevel(level)
      // setCurrentLevel(level)
      currentLevel.current = level
      incrementProgress()
      setStep(2)
    }
  }

  const selfAssessmentQuestion = getSelfAssessmentQuestion()
  console.log('selfAssessmentQuestion', selfAssessmentQuestion)
  let titles =
    selfAssessmentQuestion?.answers.map((answer) => answer.text) ?? []
  let descriptions = selfAssessmentQuestion?.hints ?? []

  if (selfAssessmentQuestion?.courses) {
    titles =
      selfAssessmentQuestion?.courses.map(
        (course) => course.name + ' ' + course.title,
      ) ?? []
    descriptions =
      selfAssessmentQuestion?.courses.map((course) => course.description) ?? []
  }

  // analytics
  useEffect(() => {
    if (candidateId) {
      postActivityLog(ActivityLogEvent.visit, window.location.href)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId])

  return (
    <div className="bg-black h-screen">
      <div className="p-8 bg-white space-y-8">
        <div className="flex justify-center">
          <ProgressIndicator />
        </div>
        <div className="text-4xl font-bold">Self-assessment</div>
        <Accordion titles={titles} descriptions={descriptions} />
        {/* <Flashcards titles={titles} descriptions={descriptions} /> */}
        <MultipleChoiceCard
          questionNumber={0}
          questionText={selfAssessmentQuestion?.text ?? 'na'}
          questionId={'0'}
          answers={selfAssessmentQuestion?.answers ?? []}
          callback={next}
          submitText="Submit and proceed to test"
        />
      </div>
      <Footer />
    </div>
  )
}
