import React, { useState } from 'react'

interface Props {
  titles: string[]
  descriptions: string[]
}

const AccordionItem: React.FC<{
  title: string
  content: string
}> = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded)
  }

  return (
    <div className="accordion-item">
      <div
        className={`accordion-header ${isExpanded ? '' : 'collapsed'}`}
        onClick={handleToggle}
        aria-expanded={isExpanded}
      >
        <div className="accordion-title font-bold">{title}</div>
        <div className="accordion-icon"></div>
      </div>
      {isExpanded && (
        <div className="accordion-body">
          <p>{content}</p>
        </div>
      )}
    </div>
  )
}

const Accordion: React.FC<Props> = ({ titles, descriptions }) => {
  return (
    <div className="accordion-container">
      {titles.map((title, index) => {
        return (
          <AccordionItem
            title={titles[index]}
            content={descriptions[index]}
            key={`acc-${index}`}
          />
        )
      })}
    </div>
  )
}

export default Accordion
