// import * as url from 'url';
// import * as querystring from 'querystring';

export const isEmail = (email: string): boolean => {
  const expression: RegExp =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
  return expression.test(email)
}

export const getUrlParam = (param: string): string => {
  const currentPath = window.location.href
  // console.log('currentPath', currentPath)
  const parsedUrl = new URL(currentPath)
  // console.log('parsedUrl', parsedUrl)
  const params = new URLSearchParams(parsedUrl.search)
  const value = params.get(param) || ''
  return value
}
