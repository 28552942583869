import React from 'react'
import { Link } from 'react-router-dom'
// interface HeaderProps {
//   media: string
//   title: string
//   subheading: string
//   description: string
// }

const Footer: React.FC<any> = () => {
  return (
    <div className="bg-white pt-4 w-full footer">
      <div className="text-white flex flex-col space-y-2 items-center justify-center h-52 w-full">
        <div className="space-x-8 flex">
          <Link to="https://mentem.co/about/">About Mentem</Link>
          <Link to="https://mentem.co/privacy/">Privacy Policy</Link>
          <Link to="https://mentem.co/contact/">Contact Us</Link>
        </div>
        <div>@ 2023 Mentem by UNSW. All rights reserved.</div>
      </div>
    </div>
  )
}

export default Footer
