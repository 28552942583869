import Header from '../components/Header/HeaderBanner'
import Footer from '../components/Footer'
import { StyleUtil } from '../utils/StyleUtil'
import { useDataContext } from '../context/DataProvider'
import { goToTop } from '../utils/interactions'
import { API } from '../apis/API'
import { isProduction } from '../utils/EnvUtils'
import { Assessment } from '../apis/entities/assessment.entity'

export default function DoneBefore() {
  const {
    candidateId,
    incrementProgress,
    startTest,
    setStep,
    assessment,
    adjustLevel,
    postSubmissionStart,
  } = useDataContext()

  const handleSubmitClick = async () => {
    // console.log('candidateId', candidateId)
    postSubmissionStart(candidateId, !isProduction)
    startTest()
    incrementProgress()
    goToTop()
    if (((assessment as Assessment)?.classifiedLevels.length ?? 0) > 0) {
      setStep(1)
    } else {
      adjustLevel()
      setStep(2)
    }
  }

  return (
    <div className="bg-black h-screen">
      <div className="p-8 bg-white space-y-8">
        <Header
          media={'https://cdn.mentem.co/assessment.jpg'}
          title={assessment?.title ?? ''}
          description={'Develop. Scale. Grow'}
        />
        <h1 className="text-4xl font-bold my-4">
          Thanks for completing the test!
        </h1>
        <p className="my-4">You have already completed this test.</p>
        {/* <p className="font-bold my-4">
          If you're having issues accessing the link you were provided, please
          contact{' '}
          <a className="text-purple-600" href="mailto:support@mentem.co">
            support@mentem.co
          </a>
        </p> */}
        <p className="my-4">
          If you're looking for more general information on Mentem or a demo of
          our learner experience, please check out{' '}
          <a
            className="text-purple-600"
            href="https://mentem.co"
            target="_blank"
            rel="opener noreferrer"
          >
            https://mentem.co
          </a>{' '}
          or{' '}
          <a
            className="text-purple-600"
            href="https://demo.mentem.co"
            target="_blank"
            rel="opener noreferrer"
          >
            https://demo.mentem.co
          </a>
        </p>

        {!isProduction && (
          <div className="flex justify-center">
            <button
              data-testid="btn-begin-test"
              className={StyleUtil.buttonAction}
              onClick={handleSubmitClick}
            >
              Begin test (This button only appears in local and dev
              environments)
            </button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}
