import Footer from '../components/Footer'
import MultipleChoiceCard from '../components/MultipleChoiceCard'
import ProgressIndicator from '../components/ProgressIndicator/ProgressIndicator'
import { useDataContext } from '../context/DataProvider'
import { useEffect, useState } from 'react'
import { ActivityLogEvent } from '../apis/requests/activity-log.request'
import { Question, QuestionAnswered2 } from '../apis/entities/question.entity'
import { Answer } from '../apis/entities/answer.entity'
import { AutomationUtils } from '../utils/AutomationUtils'

export default function Questions() {
  const [startTime, setStartTime] = useState(new Date())

  const {
    incrementProgress,
    setProgress,
    currentQuestion,
    questionsAnswered,
    // setQuestionsAnswered,
    finalLevel,
    updateCurrentQuestion,
    adjustLevel,
    candidateId,
    postActivityLog,
    setStep,
    version,
    currentQuestion2,
    questionsAnswered2,
    // setQuestionsAnswered2,
    checkWorkflow,
    updateResult,
    updateCurrentQuestion2,
    autoAnswerRate,
  } = useDataContext()

  // click actions
  const next = (questionId: string, answerId: string) => {
    //answerId: 0, 1, 2, 3
    console.log('questionId:', questionId, ' ', 'answerId:', answerId)

    if (version.current === 1) {
      if (answerId === undefined || currentQuestion === undefined) {
        console.log(
          `answerId is ${answerId} or currentQuestion is ${currentQuestion}`,
        )
        return
      }
      const answer = (currentQuestion as Question).answers.filter(
        (answer) => answer.id === answerId,
      )[0]

      const timeTaken = (new Date().getTime() - startTime.getTime()) / 1000
      console.log('timeTaken', timeTaken)

      const score = answer.isCorrect ? 1 : 0
      console.log('score', score)

      questionsAnswered.current = [
        ...questionsAnswered.current,
        {
          id: currentQuestion.id,
          text: (currentQuestion as Question).text,
          level: currentQuestion.level,
          answer: answer.text,
          index: questionsAnswered.current.length + 1,
          timeTaken: timeTaken,
          score: score,
        },
      ]
    } else if (version.current === 2) {
      const answerIndex = Number(answerId)
      if (answerIndex === undefined || currentQuestion2 === undefined) {
        console.log(
          `answerId is ${answerId} or currentQuestion is ${currentQuestion2}`,
        )
        return
      }
      // const answer = (currentQuestion2).options.filter(
      //   (answer) => answer.id === answerId,
      // )[0]

      const timeTaken = (new Date().getTime() - startTime.getTime()) / 1000
      console.log('timeTaken', timeTaken)
      // console.log('answer:',answerIndex, currentQuestion2.answerIndex)

      const score = answerIndex === currentQuestion2.answerIndex ? 1 : 0
      console.log('score', score)

      const currentAnswer: QuestionAnswered2 = {
        id: currentQuestion2._id,
        questionText: currentQuestion2.name,
        level: currentQuestion2.level,
        answer: currentQuestion2.options[answerIndex],
        questionNumber: questionsAnswered2.current.length + 1,
        timeTaken: timeTaken,
        score: score,
        focusArea: currentQuestion2.focusArea,
        capability: currentQuestion2.capability,
        requirement: currentQuestion2.requirement,
        // difficulty: currentQuestion2.difficulty,
        // complexity: currentQuestion2.complexity,
        // autonomy: currentQuestion2.autonomy,
      }

      questionsAnswered2.current = [
        ...questionsAnswered2.current,
        currentAnswer,
      ]

      // log answer
      postActivityLog(ActivityLogEvent.answer, JSON.stringify(currentAnswer))

      updateResult(currentAnswer)
    }

    // update level and confidence
    if (version.current === 1) {
      adjustLevel()
    } else if (version.current === 2) {
      checkWorkflow()
    }

    //will still get called after the last question. to be refactored

    // update progress ui
    incrementProgress()

    // set next question
    setStartTime(new Date())

    console.log('====================')
    if (version.current === 1) {
      updateCurrentQuestion()
    } else if (version.current === 2) {
      updateCurrentQuestion2()
    }
  }

  //---------------- start use effects ----------------

  // start test, call it once
  useEffect(() => {
    if (version.current === 1) {
      updateCurrentQuestion()
    } else if (version.current === 2) {
      updateCurrentQuestion2()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // finish test
  useEffect(() => {
    if (finalLevel > -1) {
      setProgress(1)
      // navigate to end page
      setStep(3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalLevel, setProgress])

  // analytics
  useEffect(() => {
    if (candidateId) {
      postActivityLog(ActivityLogEvent.visit, window.location.href)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId])

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV === 'local' ||
      process.env.REACT_APP_ENV === 'dev'
    ) {
      if (autoAnswerRate !== undefined) {
        AutomationUtils.start(autoAnswerRate!)
      }
    }
  }, [autoAnswerRate])

  //---------------- end use effects ----------------

  const questionNumber = Math.max(
    questionsAnswered.current.length + 1,
    questionsAnswered2.current.length + 1,
  )

  function shuffleArray<T>(array: T[]): T[] {
    const shuffledArray = [...array]

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ]
    }
    return shuffledArray
  }

  let answers: Answer[] = []
  if (version.current === 2) {
    console.log('currentQuestion2', currentQuestion2)
    answers =
      currentQuestion2?.options.map((option, index) => {
        return {
          id: `${index}`,
          text: option,
          isCorrect: index === currentQuestion2?.answerIndex,
        } as Answer
      }) ?? []
    if (
      process.env.REACT_APP_ENV !== 'local' &&
      process.env.REACT_APP_ENV !== 'dev'
    ) {
      answers = shuffleArray(answers)
    }
  } else if (version.current === 1) {
    console.log('currentQuestion', currentQuestion)
    answers =
      process.env.REACT_APP_ENV === 'local'
        ? currentQuestion?.answers ?? []
        : shuffleArray(currentQuestion?.answers ?? [])
  }

  return (
    <div className="h-screen flex flex-col items-center">
      <div className="relative lg:max-w-[1000px] xl:max-w-[1200px] w-full h-full flex flex-col">
        <div className="p-8 bg-white grow">
          <div className="space-y-4">
            <div className="flex justify-center">
              <ProgressIndicator />
            </div>
            <div className="text-4xl font-['Inter'] font-bold mx-8 py-8">
              Question {questionNumber}
            </div>
            {version.current !== 0 && (
              <MultipleChoiceCard
                questionNumber={questionNumber}
                questionText={
                  currentQuestion2?.name ?? currentQuestion?.text ?? 'na'
                }
                questionId={
                  currentQuestion2?._id ?? currentQuestion?.id ?? 'na'
                }
                answers={answers}
                callback={next}
                submitText="Next"
              />
            )}
            {/* {version.current === 2 && (
              <MultipleChoiceCard2
                questionNumber={questionNumber}
                questionText={(currentQuestion2)?.name ?? 'na'}
                questionId={(currentQuestion2)?._id ?? 'na'}
                options={currentQuestion2?.options ?? []}
                answerIndex={currentQuestion2?.answerIndex ?? 0}
                shuffle={true}//{process.env.REACT_APP_ENV === 'local'}
                callback={next}
                submitText="Next"
              />
            )} */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
